import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Chip,
    Autocomplete,
    Button,
    Typography,
    Fade,
    CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { put, get, del } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';
import { fetchAuthSession } from 'aws-amplify/auth';
import ReactMarkdown from 'react-markdown';

const emotionOptions = ['Happy', 'Sad', 'Excited', 'Anxious', 'Calm', 'Grateful'];

function JournalEntryPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { entryId } = useParams();
    const isNewEntry = !entryId;
    const [isEditing, setIsEditing] = useState(isNewEntry);
    const [date, setDate] = useState(new Date());
    const [emotions, setEmotions] = useState([]);
    const [text, setText] = useState('');
    const [id, setId] = useState(entryId || null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [interpretation, setInterpretation] = useState(''); // State to hold interpretation result
    const [loading, setLoading] = useState(false); // State to handle loading spinner

    const apiName = 'dreamapp';

    useEffect(() => {
        const fetchEntry = async () => {
            try {
                if (!isNewEntry) {
                    const { idToken } = (await fetchAuthSession()).tokens ?? {};

                    if (!idToken) {
                        throw new Error('No ID token found');
                    }

                    const restOperation = get({
                        apiName,
                        path: `/users/entry/${entryId}`,
                        options: {
                            headers: {
                                Authorization: `Bearer ${idToken}`,
                            },
                        },
                    });

                    const response = await restOperation.response;
                    const responseData = await response.body.json();

                    const entryDate = responseData.date ? new Date(responseData.date) : new Date();
                    const entryEmotions = Array.isArray(responseData.emotions) ? responseData.emotions : [];
                    const entryText = typeof responseData.text === 'string' ? responseData.text : '';
                    const responseEntryId = responseData.Id ?? entryId;
                    const existingInterpretation = responseData.interpretation || '';

                    setDate(entryDate);
                    setEmotions(entryEmotions);
                    setText(entryText);
                    setId(responseEntryId);
                    setInterpretation(existingInterpretation); // Set existing interpretation if present
                    setContentLoaded(true);
                } else {
                    setContentLoaded(true);
                    setIsEditing(true);
                }
            } catch (error) {
                console.error('Error fetching entry:', error);
                setError(error.message || 'An error occurred while fetching the entry');
                setContentLoaded(true);
            }
        };

        fetchEntry();
    }, [entryId, isNewEntry]);

    const handleSave = async () => {
        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            if (!idToken) {
                throw new Error('No ID token found');
            }

            const entryIdToUse = id || uuidv4();
            const updatedEntry = {
                Id: entryIdToUse,
                date: date.toISOString(),
                emotions,
                text,
            };

            await put({
                apiName,
                path: '/users/entries',
                options: {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                    body: updatedEntry,
                },
            });

            setIsEditing(false);
            if (isNewEntry) {
                navigate(`/journal-entry/view/${entryIdToUse}`);
            }
        } catch (error) {
            setError(error.message || 'An error occurred while saving the entry');
        }
    };

    // Handle interpreting the dream entry
    const handleInterpret = async () => {
        try {
            setLoading(true); // Show loading spinner while interpreting
            setInterpretation(''); // Clear previous interpretation

            // Get the ID token for authorization
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            if (!idToken) {
                throw new Error('No ID token found');
            }

            // Define the payload to send to the Lambda function via the API
            const interpretationRequest = {
                eventId: id || uuidv4(), // Use the entry ID or generate a new UUID
                body: text, // The dream text to be analyzed
                emotions, // Optional: Send emotions if needed for interpretation context
            };

            console.log("Sending interpretation request:", interpretationRequest);

            // Make a PUT request to the interpret endpoint of your API
            const restOperation = await put({
                apiName, // The API name defined in Amplify
                path: '/interpret', // Path to your interpret Lambda function
                options: {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                    body: interpretationRequest,
                },
            });

            const response = await restOperation.response;
            const responseData = await response.body.json();

            // Check the structure of the response
            console.log("Received response:", responseData);

            // Extract the interpretation result from the response safely
            const interpretationText = responseData.response || 'No interpretation available. Please try again later.'; // Default message if empty
            console.log('Interpretation Result:', interpretationText);

            // Update the state with the interpretation result
            setInterpretation(interpretationText);
        } catch (error) {
            console.error('Error interpreting entry:', error);
            setError(error.message || 'An error occurred while interpreting the entry');
        } finally {
            setLoading(false); // Hide loading spinner after interpretation is done
        }
    };

    // Handle deleting the journal entry
    const handleDelete = async () => {
        if (!id) {
            console.error('No entry ID available for deletion.');
            return;
        }

        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            if (!idToken) {
                throw new Error('No ID token found');
            }

            await del({
                apiName,
                path: `/users/entry/${id}`, // Use the correct endpoint for deletion
                options: {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                },
            });

            console.log('Entry deleted successfully:', id);
            navigate('/timeline'); // Redirect to the journal overview page after deletion
        } catch (error) {
            console.error('Error deleting entry:', error);
            setError(error.message || 'An error occurred while deleting the entry');
        }
    };


    const getDayOfWeek = (dateString) => {
        const dateObj = new Date(dateString);
        const options = { weekday: 'long' };
        return dateObj.toLocaleDateString(undefined, options);
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return dateObj.toLocaleDateString(undefined, options); // Example: 19 Sep, 2024
    };

    return (
        <Box sx={{ padding: '20px' }}>
            {contentLoaded ? (
                error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <Fade in={isEditing} timeout={{ enter: 1000, exit: 1000 }}>
                            <div style={{ display: isEditing ? 'block' : 'none' }}>
                                {/* Editable Fields */}
                                <DatePicker
                                    label="Date"
                                    value={date}
                                    onChange={(newDate) => setDate(newDate)}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth />
                                    )}
                                    sx={{ marginBottom: '20px' }}
                                />

                                <Autocomplete
                                    multiple
                                    options={emotionOptions}
                                    value={emotions}
                                    onChange={(event, newValue) => setEmotions(newValue)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} color="secondary" {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Feeling" fullWidth />
                                    )}
                                    sx={{ marginBottom: '20px' }}
                                />

                                <TextField
                                    label="Journal Entry"
                                    multiline
                                    rows={6}
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    fullWidth
                                    sx={{ marginBottom: '20px', textTransform: 'none' }} // Ensures text renders in normal case
                                    inputProps={{ style: { textTransform: 'none' } }} // Explicitly sets input text to normal case
                                />

                                <Button variant="contained" color="primary" onClick={handleSave} fullWidth>
                                    Save Entry
                                </Button>
                            </div>
                        </Fade>

                        <Fade in={!isEditing} timeout={{ enter: 1000, exit: 1000 }}>
                            <div style={{ display: !isEditing ? 'block' : 'none' }}>
                                {/* Read-Only Display */}
                                <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                                    {getDayOfWeek(date)} {formatDate(date)}
                                </Typography>

                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Feeling:
                                    </Typography>
                                    {emotions.map((emotion, index) => (
                                        <Chip key={index} label={emotion} color="secondary" />
                                    ))}
                                </Box>

                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Journal Entry:
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ whiteSpace: 'pre-wrap', textTransform: 'none' }} // Normalizes text casing
                                    >
                                        {text}
                                    </Typography>
                                </Box>

                                {/* Loading Spinner with Magic Sparkles */}
                                {loading && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '20px' }}>
                                        <CircularProgress />
                                        <Typography variant="body1" sx={{ color: '#ff8c00' }}>
                                            Interpreting...
                                        </Typography>
                                    </Box>
                                )}

                                {/* Interpretation Result */}
                                {!loading && interpretation && (
                                    <Box sx={{ marginBottom: '20px' }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Interpretation:
                                        </Typography>
                                        <ReactMarkdown
                                            components={{
                                                p: ({ node, ...props }) => (
                                                    <Typography
                                                        {...props}
                                                        sx={{ whiteSpace: 'pre-wrap', textTransform: 'none' }} // Ensures text renders normally
                                                    />
                                                ),
                                                li: ({ node, ...props }) => (
                                                    <li style={{ textTransform: 'none' }}>
                                                        <Typography
                                                            component="span"
                                                            {...props}
                                                            sx={{ display: 'inline', textTransform: 'none' }}
                                                        />
                                                    </li>
                                                ),
                                                strong: ({ node, ...props }) => (
                                                    <strong style={{ textTransform: 'none' }}>
                                                        <Typography
                                                            component="span"
                                                            {...props}
                                                            sx={{ fontWeight: 'bold', textTransform: 'none' }}
                                                        />
                                                    </strong>
                                                ),
                                            }}
                                        >
                                            {interpretation}
                                        </ReactMarkdown>
                                    </Box>
                                )}

                                {/* Interpret Button */}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleInterpret}
                                    fullWidth
                                    sx={{ marginBottom: '20px' }}
                                    disabled={!!interpretation} // Disable if interpretation already exists
                                >
                                    Interpret
                                </Button>

                                {/* Buttons Row */}
                                <Box sx={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                                    <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)} sx={{ flex: 1 }}>
                                        Edit
                                    </Button>

                                    <Button variant="outlined" color="secondary" onClick={handleDelete} sx={{ flex: 1 }}>
                                        Delete
                                    </Button>
                                </Box>
                            </div>
                        </Fade>
                    </>
                )
            ) : (
                <Typography variant="h6">Loading...</Typography>
            )}
        </Box>
    );
}

export default JournalEntryPage;
