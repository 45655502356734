import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
    Timeline as MuiTimeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';
import { Paper, Typography } from '@mui/material';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { Link } from 'react-router-dom';
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

function Timeline() {
    const apiName = 'dreamapp';
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [nextToken, setNextToken] = useState(null);
    const [error, setError] = useState(null);

    const scrollableDivRef = useRef(null);

    const LIMIT = 10;

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: '2-digit' };
        return dateObj.toLocaleDateString(undefined, options); // Example: 19 Sep, 2024
    };

    const getDayOfWeek = (dateString) => {
        const dateObj = new Date(dateString);
        const options = { weekday: 'short' };
        return dateObj.toLocaleDateString(undefined, options);
    };

    const getEntryTitle = (entry) => {
        if (entry.text) {
            return entry.text.substring(0, 30) + '...';
        } else if (entry.entry) {
            return entry.entry.substring(0, 30) + '...';
        } else {
            return 'No content';
        }
    };

    const fetchJournalEntries = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        setError(null);
        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            if (!idToken) {
                throw new Error('No ID token found');
            }

            const restOperation = get({
                apiName,
                path: '/users/entries',
                options: {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                    queryParams: {
                        limit: LIMIT,
                        ...(nextToken ? { nextToken } : {}),
                    },
                },
            });

            const response = await restOperation.response;
            const responseData = await response.body.json();

            if (!responseData.entries || !Array.isArray(responseData.entries)) {
                throw new Error('Unexpected response format');
            }

            // Sort entries by date in descending order (newest first)
            const sortedEntries = responseData.entries.sort((a, b) => new Date(b.date) - new Date(a.date));

            // Map new events and avoid duplicates by checking existing IDs
            const newEvents = sortedEntries.map((entry) => ({
                id: entry.Id,
                date: entry.date,
                title: getEntryTitle(entry),
            }));

            // Merge new events with existing ones, ensuring no duplicates
            setEvents((prevEvents) => {
                const existingIds = new Set(prevEvents.map(event => event.id));
                const uniqueNewEvents = newEvents.filter(event => !existingIds.has(event.id));
                return [...prevEvents, ...uniqueNewEvents];
            });

            setNextToken(responseData.nextToken);
            setHasMore(!!responseData.nextToken);
        } catch (error) {
            console.error('Error fetching journal entries:', error);
            setError('Failed to fetch entries. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, [nextToken, loading, hasMore]);

    useEffect(() => {
        const handleScroll = () => {
            if (!scrollableDivRef.current || !hasMore || loading) return;

            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                fetchJournalEntries();
            }
        };

        const scrollDiv = scrollableDivRef.current;
        if (scrollDiv) {
            scrollDiv.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollDiv) {
                scrollDiv.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, loading, fetchJournalEntries]);

    useEffect(() => {
        fetchJournalEntries();
    }, [fetchJournalEntries]);

    return (
        <div style={{ minHeight: '100vh', overflow: 'visible', display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '10px', position: 'sticky', top: 0, zIndex: 1, textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    DreamLine
                </Typography>
            </div>

            <div ref={scrollableDivRef} style={{ flex: 1, overflowY: 'visible', padding: '0px' }}>
                {error && (
                    <Typography color="error" style={{ textAlign: 'center', marginBottom: '0px' }}>
                        {error}
                    </Typography>
                )}
                <MuiTimeline position="alternate" style={{ padding: '0px' }}>
                    {events.map((event, index) => (
                        <TimelineItem key={`${event.id}-${index}`}>
                            <TimelineSeparator>
                                <TimelineDot color="secondary">
                                    <NightsStayIcon />
                                </TimelineDot>
                                {index < events.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Link to={`/journal-entry/view/${event.id}`} style={{ textDecoration: 'none' }}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: 2,
                                            width: {
                                                xs: '100px', // Sets width to 120px on extra-small screens (mobile)
                                                md: 'auto',  // Allows the width to be automatic or set by content on medium screens (desktop) and above
                                            },
                                            maxWidth: '100%', // Ensures it does not exceed the viewport width
                                        }}
                                    >
                                        {/* Date and Day of the Week */}
                                        <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: '0.6rem' }}>
                                            {getDayOfWeek(event.date)} {formatDate(event.date)}
                                        </Typography>
                                        {/* Journal Entry (Title) */}
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                marginTop: '8px',
                                                textTransform: 'capitalize',
                                                fontSize: '0.6rem' // Adjust this value to make the text smaller
                                            }}
                                        >                                            {event.title}
                                        </Typography>
                                    </Paper>
                                </Link>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </MuiTimeline>
                {hasMore && loading && (
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <Typography variant="body1" color="primary">
                            Loading more entries...
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Timeline;