// src/components/ProfileIcon.js
import React from 'react';
import { IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';

function ProfileIcon() {
    return (
        <IconButton
            component={Link}
            to="/profile"
            sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: 'primary.main',
            }}
        >
            <AccountCircleIcon />
        </IconButton>
    );
}

export default ProfileIcon;
