// src/components/AnimatedRoutes.js
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import HomePage from './HomePage';
import Timeline from './Timeline';
import Calendar from './Calendar';
import Profile from './Profile';
import JournalEntryPage from './JournalEntryPage';
import BackButton from './BackButton';
import ProfileIcon from './ProfileIcon';
import FeedbackForm from './FeedbackForm';

const fadeTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 }, // 0.5 second fade transition
};

function AnimatedRoutes({ signOut }) {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <motion.div key={location.pathname} {...fadeTransition}>
                <Box
                    sx={{
                        width: {
                            xs: '350px', // Applies to extra-small screens (mobile)
                            md: '500px', // Applies to medium screens (desktop) and above
                        },
                        maxWidth: '100%',
                        backgroundColor: 'background.default',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        padding: '20px',
                        boxSizing: 'border-box',
                        borderRadius: '16px',
                        margin: '10px',
                    }}
                >
                    <Routes location={location} key={location.pathname}>
                        <Route path="/" element={<HomePage signOut={signOut} />} />
                        <Route
                            path="/timeline"
                            element={
                                <>
                                    <BackButton to="/" />
                                    <ProfileIcon />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <Timeline />
                                    </Box>
                                </>
                            }
                        />
                        <Route
                            path="/calendar"
                            element={
                                <>
                                    <BackButton />
                                    <ProfileIcon />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <Calendar />
                                    </Box>
                                </>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <>
                                    <BackButton to="/" />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <Profile />
                                    </Box>
                                </>
                            }
                        />
                        <Route
                            path="/journal-entry"
                            element={
                                <>
                                    <BackButton />
                                    <ProfileIcon />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <JournalEntryPage />
                                    </Box>
                                </>
                            }
                        />
                        <Route
                            path="/journal-entry/:entryId"
                            element={
                                <>
                                    <BackButton />
                                    <ProfileIcon />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <JournalEntryPage />
                                    </Box>
                                </>
                            }
                        />
                        <Route
                            path="/journal-entry/view/:entryId"
                            element={
                                <>
                                    <BackButton to="/timeline" />
                                    <ProfileIcon />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <JournalEntryPage />
                                    </Box>
                                </>
                            }
                        />
                        <Route
                            path="/feedback"
                            element={
                                <>
                                    <BackButton to="/profile" />
                                    <Box sx={{ paddingTop: '50px' }}>
                                        <FeedbackForm />
                                    </Box>
                                </>
                            }
                        />
                    </Routes>
                </Box>
            </motion.div>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
