// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Typography } from '@mui/material';
import { Authenticator, ThemeProvider as AmplifyThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import AnimatedRoutes from './components/AnimatedRoutes';
import catImage from './assets/cute_cat_blinking_tail_wag.png'; // Update with the path to your image

Amplify.configure(awsconfig);

// Styled component for the profile picture
const CatPicture = styled('img')(({ theme }) => ({
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  marginBottom: theme.spacing(2),
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6B6B', // Vibrant coral
    },
    secondary: {
      main: '#FFD93D', // Vibrant yellow
    },
    background: {
      default: '#F3F1F5', // Light lavender
    },
  },
  typography: {
    fontFamily: 'Press Start 2P', // Fun, game-like font
    button: {
      textTransform: 'uppercase',
      fontSize: '1rem',
    },
    allVariants: {
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const amplifyTheme = {
  name: 'custom-auth-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#ffffff' }, // Main background color
      },
      font: {
        primary: { value: '#000000' }, // Primary font color
        secondary: { value: '#000000' }, // Secondary font color
        interactive: { value: '#808080' }, // Color for interactive text like links (gray)
        inverse: { value: '#000000' }, // Text color for elements on colored backgrounds
      },
      border: {
        primary: { value: '#63e5ff' }, // Border color
      },
    },
    radii: {
      small: { value: '12px' }, // Rounded edges for small elements
      medium: { value: '16px' }, // Rounded edges for larger elements like boxes
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#63e5ff' }, // Button background color
          color: { value: '#ffffff' }, // Button text color
          _hover: {
            backgroundColor: { value: '#56d4e5' }, // Slightly darker color on hover
          },
        },
      },
      authenticator: {
        container: {
          borderRadius: { value: '{radii.medium}' }, // Applies the rounded corners to the Authenticator container
        },
        form: {
          borderRadius: { value: '{radii.medium}' }, // Applies the rounded corners to the form within the Authenticator
        },
      },
      link: {
        color: { value: '#808080' }, // Sets link text color to gray
        _hover: {
          color: { value: '#606060' }, // Slightly darker gray on hover
        },
      },
    },
  },
};

function App() {
  const { route } = useAuthenticator((context) => [context.route]); // Access the current route state

  window.authRoute = route;


  return (

    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Box
          sx={{
            width: '100vw',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
            padding: 4,
          }}
        >
          {/* Render the Cat Image and DREAMCAT logo only on the sign-in page */}
          {(route === 'signIn' || route === 'signUp') && (
            <>
              <CatPicture src={catImage} alt="Dreamcat" />
              <Typography
                variant="h2"
                sx={{
                  marginBottom: 4,
                  fontFamily: 'Press Start 2P',
                  color: '#FF6B6B',
                  fontWeight: 'bold',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                }}
              >
                DREAMCAT
              </Typography>
              <Typography variant="subtitle1" sx={{
                marginBottom: 4,
                color: '#FFFFFF',
                fontWeight: 'bold',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}>
                DREAM JOURNAL | DREAM Interpretation
              </Typography>
            </>
          )}

          {/* Authentication and Routes */}
          <AmplifyThemeProvider theme={amplifyTheme}>
            <Router>
              <Box
                sx={{
                  borderRadius: '30px', // Adjust to the desired roundness
                  overflow: 'hidden', // Ensures internal content also adheres to rounded corners
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Authenticator>
                  {({ signOut }) => (

                    <AnimatedRoutes signOut={signOut} />

                  )}
                </Authenticator>
              </Box>
            </Router>
          </AmplifyThemeProvider>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>

  );
}

export default App;
