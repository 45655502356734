import React, { useState } from 'react';
import { Box, TextField, Typography, Button, Rating, Checkbox, FormControlLabel, Alert } from '@mui/material'; // Import Alert for displaying the message
import { put } from 'aws-amplify/api'; // Import Amplify API functions
import { fetchAuthSession } from 'aws-amplify/auth';

const apiName = 'dreamapp'; // Define your API name here

function FeedbackForm() {
    const [feedback, setFeedback] = useState('');
    const [rating, setRating] = useState(0);
    const [canContact, setCanContact] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); // State for success message

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSuccessMessage(''); // Clear any previous success message
        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            // Construct the feedback object
            const feedbackData = {
                feedback,
                rating,
                canContact
            };

            // Directly send feedback data to the backend
            const restOperation = await put({
                apiName,
                path: '/profile', // Assuming this endpoint is used for updating the profile
                options: {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                    body: {
                        feedback: feedbackData, // Pass feedback data directly
                    },
                },
            });

            const responseraw = await restOperation.response;
            const response = await responseraw.body.json();

            console.log("Status code is :", responseraw.statusCode)

            if (responseraw.statusCode === 200) { // Check if the response was successful
                setSuccessMessage('Thank you for your feedback!'); // Set success message
            }

            console.log('Feedback submitted:', response);
            setFeedback('');
            setRating(0);
            setCanContact(false);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                SEND FEEDBACK
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textTransform: 'none' }}>
                Thanks for sharing your thoughts! I’m always looking for ways to make this better, and your feedback means a lot to me.
            </Typography>

            {/* Display Success Message */}
            {successMessage && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {successMessage}
                </Alert>
            )}

            <TextField
                label="Your Feedback"
                multiline
                rows={6}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
                required
            />

            <Typography component="legend" sx={{ mt: 2 }}>
                HOW WOULD YOU RATE DREAMCAT OVERALL?
            </Typography>
            <Rating
                name="rating"
                value={rating}
                onChange={(event, newValue) => {
                    setRating(newValue);
                }}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={canContact}
                        onChange={(e) => setCanContact(e.target.checked)}
                        name="canContact"
                    />
                }
                label="IT'S OKAY TO CONTACT ME ABOUT THIS FEEDBACK"
                sx={{ mt: 2, mb: 2 }}
            />

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    mt: 2,
                    borderRadius: '16px',
                    padding: '12px',
                }}
            >
                SEND
            </Button>
        </Box>
    );
}

export default FeedbackForm;
