// src/components/BackButton.js
import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function BackButton({ to }) {
    const navigate = useNavigate();

    // Handle click based on the presence of the `to` prop
    const handleClick = () => {
        if (to) {
            navigate(to); // Navigate to the specified route
        } else {
            navigate(-1); // Go back one step in history
        }
    };

    return (
        <IconButton
            onClick={handleClick}
            sx={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                color: 'primary.main',
            }}
        >
            <ArrowBackIcon />
        </IconButton>
    );
}

export default BackButton;
