import React, { useState } from 'react';
import { format, addDays, startOfWeek, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay } from 'date-fns';
import { Typography, Paper, Grid, Button } from '@mui/material';

function Calendar() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const onDateClick = (day) => {
        setSelectedDate(day);
    };

    const nextMonth = () => {
        setCurrentDate(addDays(currentDate, 30));
    };

    const prevMonth = () => {
        setCurrentDate(addDays(currentDate, -30));
    };

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Button onClick={prevMonth}>&lt;</Button>
                </Grid>
                <Grid item>
                    <Typography variant="h5">{format(currentDate, dateFormat)}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={nextMonth}>&gt;</Button>
                </Grid>
            </Grid>
        );
    };

    const renderDays = () => {
        const dateFormat = "EEEE";
        const days = [];
        let startDate = startOfWeek(currentDate);
        for (let i = 0; i < 7; i++) {
            days.push(
                <Grid item key={i} xs>
                    <Typography align="center">{format(addDays(startDate, i), dateFormat)}</Typography>
                </Grid>
            );
        }
        return <Grid container>{days}</Grid>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <Grid item key={day} xs>
                        <Button
                            fullWidth
                            onClick={() => onDateClick(cloneDay)}
                            style={{
                                backgroundColor: isSameDay(day, selectedDate) ? "#7986cb" :
                                    isSameMonth(day, monthStart) ? "transparent" : "#f0f0f0"
                            }}
                        >
                            <Typography>{formattedDate}</Typography>
                        </Button>
                    </Grid>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <Grid container key={day}>
                    {days}
                </Grid>
            );
            days = [];
        }
        return <div>{rows}</div>;
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>Calendar</Typography>
            <Paper elevation={3} sx={{ padding: 2, maxWidth: 800, margin: 'auto' }}>
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </Paper>
        </div>
    );
}

export default Calendar;