import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio, Grid, TextField } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { get, put } from 'aws-amplify/api';
import cuteCatImage from '../assets/cute_cat_blinking_tail_wag.png';
import { fetchAuthSession } from 'aws-amplify/auth';

function Profile() {
    const theme = useTheme();
    const { signOut, user } = useAuthenticator((context) => [context.signOut, context.user]);
    const navigate = useNavigate();

    const [gender, setGender] = useState('prefer-not-to-say');
    const [birthDate, setBirthDate] = useState(null);
    const [starSign, setStarSign] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const apiName = 'dreamapp';
    const username = user?.username || 'User';
    const email = user?.attributes?.email || 'email@example.com';

    const ProfilePicture = styled('img')(({ theme }) => ({
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        marginBottom: theme.spacing(2),
    }));

    // Function to determine the star sign based on the birth date
    const getStarSign = (date) => {
        if (!date) return '';
        const day = date.getDate();
        const month = date.getMonth() + 1;

        if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) return 'Aquarius';
        if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) return 'Pisces';
        if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) return 'Aries';
        if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) return 'Taurus';
        if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) return 'Gemini';
        if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) return 'Cancer';
        if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) return 'Leo';
        if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) return 'Virgo';
        if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) return 'Libra';
        if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) return 'Scorpio';
        if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) return 'Sagittarius';
        if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) return 'Capricorn';

        return '';
    };

    useEffect(() => {
        // Fetch the user's profile data when the component mounts
        const fetchProfile = async () => {
            setLoading(true);
            setError(null);
            try {
                const { idToken } = (await fetchAuthSession()).tokens ?? {};

                const restOperation = await get({
                    apiName,
                    path: `/profile`,
                    options: {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    },
                });


                const responseraw = await restOperation.response;
                const response = await responseraw.body.json();

                console.log("Resppnse from useeffect is :", response)

                setGender(response.gender || 'prefer-not-to-say');
                if (response.birthDate) {
                    const date = new Date(response.birthDate);
                    setBirthDate(date);
                    setStarSign(getStarSign(date)); // Set star sign based on the birth date
                }
            } catch (err) {
                setError('Failed to load profile data. Please try again.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [user.username]);

    const handleSave = async () => {
        const formattedBirthDate = birthDate ? birthDate.toISOString().split('T')[0] : 'Prefer not to say';

        const profileData = {
            userId: user.username,
            gender,
            birthDate: formattedBirthDate,
        };

        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            const response = await put({
                apiName,
                path: '/profile',
                options: {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                    body: profileData,
                },
            });

            console.log(response.message);
        } catch (error) {
            setError('Error updating profile. Please try again.');
            console.error('Error updating profile:', error);
        }
    };

    const handleSignOut = async () => {
        await signOut();
        navigate('/');  // Redirect to root path after sign out
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                boxSizing: 'border-box',
                position: 'relative',
            }}
        >
            <ProfilePicture src={cuteCatImage} alt="Profile Picture" />

            {/* Explanation Text */}
            <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                Setting the below information isn’t necessary, but it will give more context to make Dreamcat’s analysis better for you.
            </Typography>

            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}

            {/* Gender Selection */}
            <FormControl component="fieldset" sx={{ mb: 2, width: '100%' }}>
                <Typography variant="subtitle2" gutterBottom>
                    Select your gender (optional):
                </Typography>
                <RadioGroup
                    row
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    aria-label="gender"
                    name="gender"
                >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="prefer-not-to-say" control={<Radio />} label="Prefer not to say" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>

            {/* Birth Date Picker */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <DatePicker
                            label="Birth Date (optional)"
                            value={birthDate}
                            onChange={(newValue) => {
                                setBirthDate(newValue);
                                setStarSign(getStarSign(newValue)); // Update star sign on date change
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            inputFormat="dd-MM-yyyy"
                            clearable
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>

            {/* Display Star Sign */}
            {starSign && (
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Your Star Sign: {starSign}
                </Typography>
            )}

            {/* Save Button */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{
                    borderRadius:
                        '16px',
                    padding: '12px',
                    width: '100%',
                    fontFamily: theme.typography.fontFamily,
                    marginBottom: theme.spacing(2),
                }}
                disabled={loading}
            >
                Save
            </Button>

            <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/feedback')}
                sx={{
                    borderRadius: '16px',
                    padding: '12px',
                    width: '100%',
                    fontFamily: theme.typography.fontFamily,
                    marginBottom: theme.spacing(2),
                }}
            >
                SEND FEEDBACK
            </Button>

            <Button
                variant="outlined"
                color="primary"
                onClick={handleSignOut}
                sx={{
                    borderRadius: '16px',
                    padding: '12px',
                    width: '100%',
                    fontFamily: theme.typography.fontFamily,
                }}
            >
                SIGN OUT
            </Button>
        </Box>
    );
}

export default Profile;
