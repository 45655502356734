// src/components/HomePage.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ProfileIcon from './ProfileIcon';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import cuteCatImage from '../assets/cute_cat_blinking_tail_wag.png';

function HomePage({ signOut }) {
    const theme = useTheme();

    // Styled component for the image with rounded edges and responsive scaling
    const RoundedImage = styled('img')(({ theme }) => ({
        maxWidth: '100%', // Ensures the image scales within its container
        height: 'auto',
        borderRadius: theme.shape.borderRadius * 2,
        marginBottom: theme.spacing(2),
        display: 'block',
        margin: '0 auto', // Centers the image
    }));

    // Styled component for gradient buttons
    const GradientButton = styled(Button)(({ theme }) => ({
        borderRadius: '16px',
        padding: '16px',
        marginBottom: '16px',
        width: '90%', // Adjust for mobile
        maxWidth: '320px', // Limits button width on larger screens
        background: 'linear-gradient(45deg, #63e5ff 30%, #63e5ff 90%)',
        color: theme.palette.primary.contrastText,
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
            background: 'linear-gradient(45deg, #63e5ff 60%, #63e5ff 110%)',
        },
    }));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                padding: '20px',
                boxSizing: 'border-box',
                maxWidth: '100%', // Prevents overflow on mobile
                overflowX: 'hidden', // Hides horizontal overflow
            }}
        >
            <ProfileIcon />
            <RoundedImage src={cuteCatImage} alt="Cute Cat" />

            <Typography
                variant="h6"
                align="center"
                sx={{
                    marginBottom: theme.spacing(4),
                    fontFamily: theme.typography.fontFamily,
                    maxWidth: '90%', // Constrain width on mobile
                    overflowWrap: 'break-word', // Break words if too long
                }}
            >
                Welcome! Nice to see you again. I hope you've been dreaming big!
            </Typography>

            <Button
                variant="contained"
                color="primary"
                component={Link} to="/timeline"
                sx={{
                    borderRadius:
                        '16px',
                    padding: '12px',
                    width: '100%',
                    fontFamily: theme.typography.fontFamily,
                    marginBottom: theme.spacing(2),
                }}
            >
                TIMELINE
            </Button>
            <Button
                variant="outlined"
                color="primary"
                component={Link} to="/journal-entry/"
                sx={{
                    borderRadius: '16px',
                    padding: '12px',
                    width: '100%',
                    fontFamily: theme.typography.fontFamily,
                }}
            >
                NEW JOURNAL ENTRY
            </Button>
        </Box >
    );
}

export default HomePage;
