/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "dreamapp",
            "endpoint": "https://n10deuxc2f.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:13bf372f-cb45-4691-8d36-162864a0fabf",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_il4W9mfjx",
    "aws_user_pools_web_client_id": "2grm0p90jao4p50as30v80baq8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "userEntries-prod",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
